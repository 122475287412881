import React from 'react';

const HeaderSocials = () => {
  return (
    <div className='home__socials'>
      <a href='https://github.com/AdilsonFuxe' className='home__social-link' target='_blank'>
        <i className='fa-brands fa-github'></i>
      </a>

      <a href='https://www.linkedin.com/in/adilsonfuxe/' className='home__social-link' target='_blank'>
        <i className='fa-brands fa-linkedin'></i>
      </a>

      <a href='https://www.instagram.com/adilsonfuxe/' className='home__social-link' target='_blank'>
        <i className='fa-brands fa-instagram'></i>
      </a>

      <a href='https://x.com/adilson_fuxe' className='home__social-link' target='_blank'>
        <i className='fa-brands fa-x'></i>
      </a>


    </div>
  )
}

export default HeaderSocials;