import React from "react";

export const Card = ({data}) => {
  return(
   <div className='timeline__item'>
     <i className={data.icon}></i>
     <span className='timeline__date'>{data.year}</span>
     <h3 className='timeline__title'>{data.title}</h3>
     <p className='timeline__text'>{data.desc}</p>
   </div>
  )
}

export default Card;