// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resume__container {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1.875rem;
}

.timeline {
    background-color: var(--container-color);
    padding: 1.875rem;
    border-radius: var(--border-radius);
    position: relative;
    box-shadow: var(--shadow);
}
.timeline__item {
    position: relative;
    padding-left: 3.125rem;
    padding-bottom: 3.125rem;
}

.timeline__item:last-child {
    padding-bottom: 0;
}

.timeline__item::before {
    content: '';
    width: 1px;
    height: 100%;
    background-color: var(--first-color);
    position: absolute;
    left: .25rem;
    top: 0;
}

.timeline .icon-briefcase, .timeline  .icon-graduation {
    position: absolute;
    left: -0.4375rem;
    top: 0;
    font-size: var(--h2-font-size);
    color: var(--first-color);
    background-color: var(--container-color);
    padding-bottom: 0.4376rem 0;
}

.timeline__date {
    color: #8b88b1;
    font-size: var(--small-font-size);
}

.timeline__title {
    font-size: var(--h3-font-size);
    margin: 0.5rem 0;
}`, "",{"version":3,"sources":["webpack://./src/components/resume/resume.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,oBAAoB;AACxB;;AAEA;IACI,wCAAwC;IACxC,iBAAiB;IACjB,mCAAmC;IACnC,kBAAkB;IAClB,yBAAyB;AAC7B;AACA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,UAAU;IACV,YAAY;IACZ,oCAAoC;IACpC,kBAAkB;IAClB,YAAY;IACZ,MAAM;AACV;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,MAAM;IACN,8BAA8B;IAC9B,yBAAyB;IACzB,wCAAwC;IACxC,2BAA2B;AAC/B;;AAEA;IACI,cAAc;IACd,iCAAiC;AACrC;;AAEA;IACI,8BAA8B;IAC9B,gBAAgB;AACpB","sourcesContent":[".resume__container {\n    grid-template-columns: repeat(2, 1fr);\n    column-gap: 1.875rem;\n}\n\n.timeline {\n    background-color: var(--container-color);\n    padding: 1.875rem;\n    border-radius: var(--border-radius);\n    position: relative;\n    box-shadow: var(--shadow);\n}\n.timeline__item {\n    position: relative;\n    padding-left: 3.125rem;\n    padding-bottom: 3.125rem;\n}\n\n.timeline__item:last-child {\n    padding-bottom: 0;\n}\n\n.timeline__item::before {\n    content: '';\n    width: 1px;\n    height: 100%;\n    background-color: var(--first-color);\n    position: absolute;\n    left: .25rem;\n    top: 0;\n}\n\n.timeline .icon-briefcase, .timeline  .icon-graduation {\n    position: absolute;\n    left: -0.4375rem;\n    top: 0;\n    font-size: var(--h2-font-size);\n    color: var(--first-color);\n    background-color: var(--container-color);\n    padding-bottom: 0.4376rem 0;\n}\n\n.timeline__date {\n    color: #8b88b1;\n    font-size: var(--small-font-size);\n}\n\n.timeline__title {\n    font-size: var(--h3-font-size);\n    margin: 0.5rem 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
