// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aside {
    position: fixed;
    left: 0;
    top: 0;
    background: var(--body-color);
    border-right: 1px solid rgba(0, 0, 0, 0.05);
    padding: 2.5rem;
    width: 110px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 10;
}

.nav__list {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

.nav__link {
    font-size: 1.5rem;
    color: var(--title-color);
    font-weight: var(--font-bold);
    transition: .3s;
}

.nav__link:hover {
    color: hsl(43, 100%, 68%);
}

.copyright {
    color: hsl(245, 15%, 65%);
    font-size: var(--small-font-size);
    transform: rotate(-180deg);
    writing-mode: vertical-lr;
}`, "",{"version":3,"sources":["webpack://./src/components/sidebar/sidebar.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,OAAO;IACP,MAAM;IACN,6BAA6B;IAC7B,2CAA2C;IAC3C,eAAe;IACf,YAAY;IACZ,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,6BAA6B;IAC7B,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,iCAAiC;IACjC,0BAA0B;IAC1B,yBAAyB;AAC7B","sourcesContent":[".aside {\n    position: fixed;\n    left: 0;\n    top: 0;\n    background: var(--body-color);\n    border-right: 1px solid rgba(0, 0, 0, 0.05);\n    padding: 2.5rem;\n    width: 110px;\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n    z-index: 10;\n}\n\n.nav__list {\n    display: flex;\n    flex-direction: column;\n    row-gap: 1rem;\n}\n\n.nav__link {\n    font-size: 1.5rem;\n    color: var(--title-color);\n    font-weight: var(--font-bold);\n    transition: .3s;\n}\n\n.nav__link:hover {\n    color: hsl(43, 100%, 68%);\n}\n\n.copyright {\n    color: hsl(245, 15%, 65%);\n    font-size: var(--small-font-size);\n    transform: rotate(-180deg);\n    writing-mode: vertical-lr;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
