// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.services__container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.875rem;
}

.services__card {
    text-align: center;
    padding: 1.875rem;
    border-radius: var(--border-radius);
    background-color: var(--container-color);
    transition: all 0.3s ease-in-out;
}

.services__card:hover  {
    transform: translateY(-10px);
}

.services__card:nth-child(1) {
    background-color: rgb(108,108,229);
    box-shadow: 0px 5px 20px 0px rgba(108,108,229,50%);
}

.services__card:nth-child(2) {
    background-color: rgb(249,215,76);
    box-shadow: 0px 5px 20px 0px rgba(249,215,76,50%);
}

.services__card:nth-child(3) {
    background-color: rgb(249,123,139);
    box-shadow: 0px 5px 20px 0px rgba(249,123,139,50%);
}

.services__img {
    margin-bottom: 1.25rem;
}


.services__title {
   font-size: var(--h3-font-size);
    margin-bottom: 1rem;
    color: #fff;
}
.services__description {
    color: #f8f9fa;
}

.services__card:nth-child(2) .services__title {
    color: var(--title-color);
}

.services__title:nth-child(2) .services__description {
    color: var(--text-color);
}`, "",{"version":3,"sources":["webpack://./src/components/services/services.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,oBAAoB;AACxB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,mCAAmC;IACnC,wCAAwC;IACxC,gCAAgC;AACpC;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,kCAAkC;IAClC,kDAAkD;AACtD;;AAEA;IACI,iCAAiC;IACjC,iDAAiD;AACrD;;AAEA;IACI,kCAAkC;IAClC,kDAAkD;AACtD;;AAEA;IACI,sBAAsB;AAC1B;;;AAGA;GACG,8BAA8B;IAC7B,mBAAmB;IACnB,WAAW;AACf;AACA;IACI,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".services__container {\n    grid-template-columns: repeat(3, 1fr);\n    column-gap: 1.875rem;\n}\n\n.services__card {\n    text-align: center;\n    padding: 1.875rem;\n    border-radius: var(--border-radius);\n    background-color: var(--container-color);\n    transition: all 0.3s ease-in-out;\n}\n\n.services__card:hover  {\n    transform: translateY(-10px);\n}\n\n.services__card:nth-child(1) {\n    background-color: rgb(108,108,229);\n    box-shadow: 0px 5px 20px 0px rgba(108,108,229,50%);\n}\n\n.services__card:nth-child(2) {\n    background-color: rgb(249,215,76);\n    box-shadow: 0px 5px 20px 0px rgba(249,215,76,50%);\n}\n\n.services__card:nth-child(3) {\n    background-color: rgb(249,123,139);\n    box-shadow: 0px 5px 20px 0px rgba(249,123,139,50%);\n}\n\n.services__img {\n    margin-bottom: 1.25rem;\n}\n\n\n.services__title {\n   font-size: var(--h3-font-size);\n    margin-bottom: 1rem;\n    color: #fff;\n}\n.services__description {\n    color: #f8f9fa;\n}\n\n.services__card:nth-child(2) .services__title {\n    color: var(--title-color);\n}\n\n.services__title:nth-child(2) .services__description {\n    color: var(--text-color);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
