import './App.css';
import {Home, Portfolio, About, Services, Sidebar, Resume, Pricing, Testimonials, Blog, Contact} from './components';

function App() {
  return (
    <>
      <Sidebar/>
      <main className="main">
        <Home/>
        <About/>
        <Services/>
        <Resume/>
        <Portfolio/>
        <Pricing/>
        <Testimonials/>
        <Blog/>
        <Contact/>
      </main>
    </>
  );
}

export default App;
