import React from "react";
import "./about.css";
import Image from '../../assets/avatar-2.svg'
import AboutBox from "./about-box";

export const About = () => {
  return (
    <section className='about container section' id='about'>
      <h2 className='section__title'>About me</h2>
      <div className='about__container grid'>
        <img src={Image} alt="" className='about__img'/>

        <div className='about__data grid'>
          <div className='about__info'>
            <p className='about__description'>
              I have been a software developer since I was 14 years old, and I am currently 26. My passion for
              technology and my dedication to solving problems constantly motivate me to improve my technical and
              interpersonal skills. I have a knack for communication and teamwork, always striving to add value and
              share knowledge with colleagues. My goal is to use my knowledge to solve complex problems and add value to
              people and organizations.
            </p>
            <a href='' className='btn'>Download CV</a>
          </div>

          <div className='about__skills grid'>
            <div className='skills__data'>
              <div className='skills__titles'>
                <h3 className='skills__name'>NodeJs</h3>
                <span className='skills__number'>90%</span>
              </div>
              <div className='skills__bar'>
                <span className='skills__percentage development'></span>
              </div>
            </div>

            <div className='skills__data'>
              <div className='skills__titles'>
                <h3 className='skills__name'>MongoDb</h3>
                <span className='skills__number '>50%</span>
              </div>
              <div className='skills__bar'>
                <span className='skills__percentage development'></span>
              </div>
            </div>

            <div className='skills__data'>
              <div className='skills__titles'>
                <h3 className='skills__name'>Arquitetura Limpa</h3>
                <span className='skills__number '>70%</span>
              </div>
              <div className='skills__bar'>
                <span className='skills__percentage development'></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AboutBox/>
    </section>
  )
}