import React from "react";
import "./home.css";
import Me from '../../assets/avatar-1.svg';
import HeaderSocials from "./header-socials";
import ScrollDown from "./scroll-down";
import Shapes from "./shapes";

export const Home = () => {
  return (
    <section className='home container' id='home'>

      <div className="intro">
        <img src={Me} alt="" className="home__img"/>
        <h1 className="home__name">Adilson Fuxe</h1>
        <span className="home__education">I'm a senior Software Engineer</span>
        <HeaderSocials/>

        <a href='#contact' className='btn'>Hire Me</a>

        <ScrollDown/>
      </div>

    </section>
  )
}
